import Sidebar from "./components/Sidebar";
import { useState, useEffect, useRef } from "react";
import DashboardRoutes from "./routes/DashboardRoutes";
import { Routes, Route } from "react-router-dom";
import AuthRouter from "./routes/AuthRouter";
import { server, config } from "./env";
import axios from "axios";
import PageLoader from "./components/PageLoader";
import "./App.css";
import { useAuthStore } from "./stores/AuthStore";




function App() {
  const { isLogin, setIsLogin, role, setRole } = useAuthStore();
  const [isAllLoaded, setIsAllLoaded] = useState(false);
  const sidebarRef = useRef();

  useEffect(() => {
    checkUserLogin();
  }, []);

  const checkUserLogin = async () => {
    await axios(server + "/api/v1/auth/admin-access", config)
      .then((rsp) => {
        setIsLogin(true);
        setIsAllLoaded(true);
        setRole(rsp.data.role);
      })
      .catch((err) => {
        setIsLogin(false);
        setIsAllLoaded(true);
      });
  };

  return isAllLoaded ? (
    isLogin ? (
      <div className="nk-app-root">
        <div className="nk-main ">
          <Sidebar sidebarRef={sidebarRef} />
          <Routes>
            <Route
              path="*"
              element={<DashboardRoutes sidebarRef={sidebarRef} />}
            />
          </Routes>
        </div>
      </div>
    ) : (
      <Routes>
        <Route path="*" element={<AuthRouter />} />
      </Routes>
    )
  ) : (
    <PageLoader />
  );
}

export default App;
