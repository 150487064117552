import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { Link } from "react-router-dom";
import { EyeFill } from 'react-bootstrap-icons';

const Users = (props) => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");
  const [loader, setLoader] = useState("");
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [url, setUrl] = useState("/api/v1/admin/users/read");
  const [showPassword, setShowPassword] = useState(false);

  const getUsers = async (search) => {
    var link = "";
    if (search) {
      link = url + "?page=" + page + "&limit=" + limit + "&search=" + search;
    } else {
      link = url + "?page=" + page + "&limit=" + limit;
    }

    await axios
      .get(server + link, config)
      .then((res) => {
        setUsers(res.data.users);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const enableCustomPricing = async (id) => {
    await axios
      .put(
        server + "/api/v1/admin/user/custom-pricing/enable/" + id,
        {},
        config
      )
      .then((res) => {
        getUsers();
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });
  };

  const disableUser = async (id) => {
    await axios
      .put(server + "/api/v1/admin/user/status/" + id, {}, config)
      .then((res) => {
        getUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUsers();
  }, [page, limit]);

  const addBalance = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const data = {
      balance: e.target.balance.value,
    };

    await axios
      .post(
        server + "/api/v1/admin/users/add-balance/" + user._id,
        data,
        config
      )
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getUsers();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = {
      password: e.target.password.value,
    };

    await axios
      .post(
        server + "/api/v1/users/update-password/" + user._id,
        params,
        config
      )
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getUsers();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });
  };

  return (
    <div className="nk-wrap">
      <Header sidebarRef={props.sidebarRef} />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Users</h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row ">
                  <div className="col-md-12">
                    <div className="card shadow-sm">
                      <div className="card-inner">
                        <div className="card-filter px-4">
                          <div className="d-md-flex justify-content-end align-items-center">
                            <div className="d-md-flex justify-content-center align-items-center">
                              {/* .search */}
                              <div className="col mt-3">
                                <div className="form-group mr-3 ">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    onChange={(e) => {
                                      getUsers(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Balance</th>

                                  <th>Status</th>
                                  <th>Joined</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {users.map((user, index) => (
                                  <tr key={index}>
                                    <td>
                                      {page > 1
                                        ? (page - 1) * 10 + index + 1
                                        : index + 1}
                                    </td>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>{"$" + user.balance}</td>

                                    <td>
                                      {user.status ? (
                                        <span className="badge badge-success">
                                          Active
                                        </span>
                                      ) : (
                                        <span className="badge badge-danger">
                                          Inactive
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {new Date(user.createdAt).toString()}
                                    </td>

                                    <td>
                                      <button
                                        className="btn btn-sm btn-primary mr-2"
                                        data-toggle="modal"
                                        data-target="#add"
                                        onClick={() => {
                                          setUser(user);
                                        }}
                                      >
                                        Add Balance
                                      </button>

                                      <Link
                                        to={"/users/" + user._id}
                                        state={{ user: user }}
                                        className="btn btn-sm btn-warning mr-2"
                                      >
                                        Pricing
                                      </Link>

                                      {user.status ? (
                                        <button
                                          className="btn btn-sm btn-danger mr-2"
                                          onClick={() => {
                                            disableUser(user._id);
                                          }}
                                        >
                                          Disable User
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-sm btn-success mr-2"
                                          onClick={() => {
                                            disableUser(user._id);
                                          }}
                                        >
                                          Enable User
                                        </button>
                                      )}

                                      {user.customPricingEnabled ? (
                                        <button
                                          className="btn btn-sm btn-danger mr-2"
                                          onClick={() => {
                                            enableCustomPricing(user._id);
                                          }}
                                        >
                                          Disable Custom Pricing
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-sm btn-success mr-2"
                                          onClick={() => {
                                            enableCustomPricing(user._id);
                                          }}
                                        >
                                          Enable Custom Pricing
                                        </button>
                                      )}

                                      <button
                                        className="btn btn-sm btn-info mr-2"
                                        data-toggle="modal"
                                        data-target="#updatePassword"
                                        onClick={() => {
                                          setUser(user);
                                        }}
                                      >
                                        Update User
                                      </button>

                                      <Link
                                        to={"/users/orders/" + user._id}
                                        state={{ user: user }}
                                        className="btn btn-sm btn-warning mr-2 "
                                      >
                                        <EyeFill size={18}/>
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex justify-content-end mt-5">
                            <nav>
                              <ul className="pagination">
                                <li
                                  className={
                                    page === 1
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    onClick={(e) => {
                                      setPage(page - 1);
                                    }}
                                  >
                                    Prev
                                  </Link>
                                </li>

                                <li
                                  className="page-item active"
                                  aria-current="page"
                                >
                                  <Link className="page-link" to="">
                                    {page}{" "}
                                    <span className="sr-only">(current)</span>
                                  </Link>
                                </li>

                                <li
                                  className={
                                    page === totalPages
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    tabIndex={-1}
                                    aria-disabled={
                                      page === totalPages ? true : false
                                    }
                                    onClick={(e) => {
                                      setPage(page + 1);
                                    }}
                                  >
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" tabIndex={-1} id="add">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Balance</h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={addBalance} className="form-validate is-alter">
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    Balance
                  </label>

                  <input
                    type="number"
                    name="balance"
                    placeholder="Enter the amount to be added in user's balance"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <button className="btn btn-lg btn-primary" type="submit">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* update password */}
      <div className="modal fade" tabIndex={-1} id="updatePassword">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Update Password</h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <form
                className="form-validate is-alter"
                onSubmit={updatePassword}
              >
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    Password
                  </label>

                  <div className="form-control-wrap">
                    <span
                      className="form-icon form-icon-right "
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <em className="passcode-icon icon-show icon ni ni-eye" />
                      ) : (
                        <em className="passcode-icon icon-show icon ni ni-eye-off" />
                      )}
                    </span>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control form-control-lg"
                      id="password"
                      name="password"
                      placeholder="Enter your password"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <button className="btn btn-lg btn-primary" type="submit">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
